import "../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WbYvbOBD+vr9C9DhIIAqON+lttXDQ+3D/Q7YmjrqKZKTJJtmj//3Qi215naSbUgoBI2kyz6OZZ2a0/Pb0JhtRkP8eCGmNkyiNZsSC4ihf4fmBECFdq/iZka2Ck9/wXyqkhToa10Yd9tqfHKXAHSOrovjTL3cgmx126+8Pywi2CmATt98ODuX2TGujETQy4lpeA60AjwA697cp2/CPlgshdeMB2hN5ipsVr18aaw5aMPLK7YxSvt1KDfS1pIqfwdLBgrZW7rk9z8O1jEbq5BswskoAYeuYUNdF4fdqo4ydukY4IXVQGy06h5WxAixF09IUmGK5SSSHI4dnBYw4o6R4d3QFKt5CaicF/BOt458CaiBytLxlRBv/9ZvmFexWmSMjOymEj2afjTJk44bBivxNRraTzDUerIsZV7LRVCLsHSM1aASb5Yoq2OJg3O3aJJSwPVBbvhmzZ9rgbOl25jgfw2ujIbN+vExuerMUo+EAlJKtky5IeCcRaJAeI60NFXA0VqSQVhb4C/UbGfI6IF8viyshmQh+OAoRXY+CsYkgmUg3F0S6iSJVXipduZTraHhLuXkh7LltpPYKnGTk84TEpUq5SKL4MQkEizKy6BH/upzV68GOYkxo+VXKp9FVnm60vDcqtYATI8XVBsjI6pq6LMTYVAZ3OSCrYGssBNw+558+PY9o8MoZdcBAI9AOFFJ9FLFBIJp9WsRyKkak6WqEGsqnsTJU2jwnkTXCm60ms8s73IDRu8+9m5bXEs++65WbcWdO4vGa6IWRnco9b4AF/XBLG8uFBI2z9+SqA6LR/ryqwFIBW35QOCer9rQgaLl2Lbeg0W/MFxN3aGJYF5Nb3+U4i8OXcO/REOzP+GUdf7Q1TGZhP/uK9zVaBaQ9P3VTp+aqnnk6hJJ1ESh/ZEyGTpgG2clnLMBV3cDJ51h35dFwuzLYfmqoBQY7LnyhedGsk3DCIrrK8kIrxesXWhbFgtim4rNiQdJvuSrm8x9MxPrelnO5eq9HrQtXJ/3hHVP6K2UF/9gVR6r5x76vnfrOmqf3c5/eCOi1fnCsfxv9rnTd31jueYn97Hy52LzEr3n65hnp3r93DAexrI1SvHUgbj5yRGznvfE8FXsYc51KxoMOPv4ueqea9e9UzcB36UBBjSkSY49/lF++Pv779fnh+/9Q2gSuugwAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var error = 'j8zigd4';
export var errorBtns = 'j8zigd7';
export var errorMessage = 'j8zigd6';
export var errorTitle = 'j8zigd5';
export var pdfIndicator = 'j8zigda';
export var pdfPage = 'j8zigdb';
export var pdfThumbnail = 'j8zigde';
export var pdfThumbnails = 'j8zigdc';
export var pdfThumbnailsList = 'j8zigdd';
export var titlebar = 'j8zigd1';
export var titlebarChild = 'j8zigd2';
export var titlebarName = 'j8zigd3';
export var viewer = 'j8zigd8';
export var viewerContainer = 'j8zigd0';
export var virtuoso = 'j8zigd9';